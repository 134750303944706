import { createContext, ReactNode, useContext } from 'react';

import useLocalStorage from '../../hooks/useLocalStorage';
import { ProductOffering } from '../../interfaces';

export type CartContextType = {
  bundle: ProductOffering | null;
  setBundle: (bundle: ProductOffering | null) => void;
  removeBundle: () => void;
};

export const CartContext = createContext<any>(undefined);

interface Props {
  children: ReactNode;
  initialValue?: ProductOffering;
}

const Cart = ({ children, initialValue }: Props) => {
  const [bundle, setBundle] = useLocalStorage('bundle', initialValue ?? null);

  const removeBundle = () => {
    setBundle(null);
    localStorage.removeItem('bundle');
  };

  const value: CartContextType = {
    bundle,
    removeBundle,
    setBundle,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default Cart;

export const useCart = () => {
  const context = useContext<CartContextType>(CartContext);

  if (context === undefined) {
    throw Error('useCart must be used within a Cart provider');
  }

  return context;
};
