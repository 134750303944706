import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import type { OrderType } from '../../interfaces';

type OrderContextType = {
  order: OrderType | null;
  updateOrder: (order: OrderType) => void;
  removeOrder: () => void;
};

export const OrderContext = createContext<any>(undefined);

interface Props {
  children: ReactNode;
  initialValue?: OrderType;
}

const Order = ({ children, initialValue }: Props) => {
  const [order, setOrder] = useState<OrderType | null>(initialValue ?? null);

  useEffect(() => {
    const existingOrder = localStorage.getItem('retailOrder');
    if (existingOrder) {
      const retailOrder = JSON.parse(existingOrder);
      const { order, expirationDate } = retailOrder;
      const date = new Date();
      if (date.getTime() >= expirationDate) {
        removeOrder();
      } else {
        setOrder(order);
      }
    }
  }, []);

  const setExpirationDate = (order: OrderType) => {
    const date = new Date();
    const expirationDate = date.setDate(date.getDate() + 30);
    const retailOrder = {
      order,
      expirationDate,
    };
    localStorage.setItem('retailOrder', JSON.stringify(retailOrder));
  };

  const updateOrder = (order: OrderType) => {
    setOrder(order);
    setExpirationDate(order);
  };

  const removeOrder = () => {
    setOrder(null);
    localStorage.removeItem('retailOrder');
  };

  const value: OrderContextType = { order, removeOrder, updateOrder };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

export default Order;

export const useOrder = () => {
  const context = useContext<OrderContextType>(OrderContext);

  if (context === undefined) {
    throw Error('useOrder must be used within an Order provider');
  }

  return context;
};
