export * from './countryCodes';
export * from './countryUtils';
export * from './currencyUtils';
export * from './dateUtils';
export * from './formUtils';
export * from './localeUtils';
export * from './msisdnUtils';
export * from './numberUtils';
export * from './objectUtils';
export * from './productOfferingUtils';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const True = () => true;

export const cleanTranslationText = (text: string) => {
  // server sends back escaped new line characters. We need to revert it back to render new lines properly
  return text.replace(/\\n/g, '\n');
};

export const getErrorMessage = (errorResponse: unknown): string => {
  if (
    errorResponse &&
    typeof errorResponse === 'object' &&
    'response' in errorResponse &&
    errorResponse.response &&
    typeof errorResponse.response === 'object' &&
    'data' in errorResponse.response
  ) {
    // axios errors contain the API response in response.data
    return getErrorMessage(errorResponse.response.data);
  } else if (
    errorResponse &&
    typeof errorResponse === 'object' &&
    'error' in errorResponse &&
    typeof errorResponse.error === 'object'
  ) {
    // some API error responses are nested in an object with 'error' property
    return getErrorMessage(errorResponse.error);
  } else if (
    errorResponse &&
    typeof errorResponse === 'object' &&
    'message' in errorResponse &&
    typeof errorResponse.message === 'string'
  ) {
    return errorResponse.message;
  } else if (
    errorResponse &&
    typeof errorResponse === 'object' &&
    'error' in errorResponse &&
    typeof errorResponse.error === 'string'
  ) {
    return errorResponse.error;
  } else {
    return 'Error!';
  }
};

export const getErrorsList = (errorResponse: unknown): string[] => {
  return typeof errorResponse === 'object' &&
    errorResponse !== null &&
    'errors_list' in errorResponse &&
    Array.isArray(errorResponse.errors_list)
    ? errorResponse.errors_list
    : [];
};
