class AppEnv {
  public isLocal() {
    return this.getEnv() === 'local';
  }

  public isProd() {
    return !['local', 'dev', 'ci', 'uat', 'stage', 'test'].includes(
      this.getEnv(),
    );
  }

  /**
   * Only prod and prod-like environemnts have a stable version number (other than 0.0.0).
   */
  public isVersioned() {
    return !['local', 'dev', 'ci'].includes(this.getEnv());
  }

  private _getEnv(host: string, NODE_ENV?: string): string {
    if (
      NODE_ENV === 'development' ||
      ['localhost', '127.0.0.1'].includes(host)
    ) {
      return 'local';
    }

    const nonProdHostPrefix = host.match(
      /(.*?)\.travelwinnonproduction\.com$/,
    )?.[1];
    if (nonProdHostPrefix) {
      const parts = nonProdHostPrefix.split('.');
      return parts[parts.length - 2];
    }
    // for production environment just returning the host
    return host;
  }

  public getEnv(): string {
    const host = window.location.hostname;
    return this._getEnv(host, process.env.NODE_ENV);
  }

  public getServerEnv(host: string): string {
    return this._getEnv(host, process.env.NODE_ENV);
  }

  public isServerEnvLocal(host: string): boolean {
    const env = this.getServerEnv(host);
    return env === 'local';
  }
}

export const appEnv = new AppEnv();
