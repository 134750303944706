import { createContext, ReactNode, useContext, useMemo } from 'react';
import * as Yup from 'yup';

import { Resource } from '../../interfaces/Resources';
import {
  getFieldLabel,
  getFieldLoading,
  getFieldOptions,
  isRequiredField,
} from '../../utils/formUtils';

export type SuperFormikContextType = {
  validationSchema?: Yup.ObjectSchema;
  testIdPrefix?: string;
};

export const SuperFormikContext = createContext<
  SuperFormikContextType | undefined
>(undefined);

export const SuperFormikContextProvider = SuperFormikContext.Provider;

/**
 * Returns form field properties based on the validation schema from context.
 * Properties from schema can be overwritten by providing custom props.
 */
export const useSuperFormik = <
  Value,
  Option extends
    | { value: Value; label: string }
    | { value: Resource; label: string },
>({
  name,
  required,
  label,
  options,
  loading,
}: {
  name: string;
  required?: boolean;
  label?: ReactNode;
  options?: Option[];
  loading?: boolean;
}) => {
  const superFormikContext = useContext(SuperFormikContext);
  const validationSchema = superFormikContext?.validationSchema;
  const testIdPrefix = superFormikContext?.testIdPrefix;

  return useMemo(
    () => ({
      required:
        required ??
        (validationSchema
          ? isRequiredField(validationSchema, name)
          : undefined),
      label:
        label ??
        (validationSchema ? getFieldLabel(validationSchema, name) : undefined),
      testIdPrefix: testIdPrefix ? `${testIdPrefix}-` : '',
      options:
        options ??
        (validationSchema
          ? getFieldOptions(validationSchema, name)
          : undefined),
      loading:
        loading ??
        (validationSchema
          ? getFieldLoading(validationSchema, name)
          : undefined),
    }),
    [validationSchema, name, required, label, testIdPrefix, options, loading],
  );
};

export const useValidationSchema = () => {
  const superFormikContext = useContext(SuperFormikContext);

  if (!superFormikContext) {
    throw new Error(
      'useValidationSchema must be used within a SuperFormikContext provider',
    );
  }

  return superFormikContext.validationSchema;
};
