import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';

import { getCurrentAppVersion } from '../../monitoring/versionCheck';
import { noop } from '../../utils';

export class ConfigLoadError extends Error {
  errorCode?: string;
  responseStatus?: number;
  responseBody?: unknown;

  constructor({
    cause,
    errorCode,
    responseStatus,
    responseBody,
  }: {
    cause?: unknown;
    errorCode?: string;
    responseStatus?: number;
    responseBody?: unknown;
  } = {}) {
    super('Failed to load application configuration', { cause });
    this.name = 'ConfigLoadError';
    this.responseStatus = responseStatus;
    this.responseBody = responseBody;
    this.errorCode = errorCode;
  }
}

const getErrorCode = (response: unknown) => {
  if (
    typeof response === 'object' &&
    response !== null &&
    'error_code' in response &&
    typeof response.error_code === 'string'
  ) {
    return response.error_code;
  }

  return undefined;
};

// lint rule disabled because Config is extended in each package
// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-explicit-any
export interface Config extends Record<string, any> {}

const ConfigContext = createContext<Config | undefined>(undefined);

let _config: Config | undefined;

export interface ConfigProviderProps {
  children?: ReactNode;
  fallback?: ReactElement;
  url: string;
  onLoad?: (config: Config) => void;
  schema?: yup.ObjectSchema<Config>;
  throwOnError?: boolean;
  onError?: (e: unknown) => void;
  appOrigin?: string;
}

/**
 * Loads the configuration from the provided url. Displays the provided fallback while the configuration is loading.
 * Accepts a yup schema that will be applied when the config is fetched.
 * For examples of usage see the tests.
 */
export const ConfigProvider = ({
  children,
  fallback,
  url,
  onLoad,
  schema,
  throwOnError,
  onError = noop,
  appOrigin,
}: ConfigProviderProps) => {
  const [config, setConfig] = useState<Config>();
  const [error, setError] = useState<unknown>();
  // the latest ref pattern
  const onLoadRef = useRef(onLoad);

  useLayoutEffect(() => {
    onLoadRef.current = onLoad;
  });

  useEffect(() => {
    let canFireLoadEvent = true;

    const fetchConfig = async () => {
      const headers: Record<string, string> = {
        'X-APP-VERSION': getCurrentAppVersion(),
      };

      if (appOrigin) {
        headers['X-APP-ORIGIN'] = appOrigin;
      }

      const response = await fetch(url, { headers });

      const json: unknown = await response.json();

      if (!response.ok) {
        throw new ConfigLoadError({
          errorCode: getErrorCode(json),
          responseStatus: response.status,
          responseBody: json,
        });
      }

      let parsedConfig: Config;

      if (schema) {
        parsedConfig = await schema.validate(json);
      } else {
        parsedConfig = json as Config;
      }

      setConfig(parsedConfig);
      _config = parsedConfig;

      if (onLoadRef.current && canFireLoadEvent) {
        onLoadRef.current(parsedConfig);
      }
    };

    fetchConfig().catch((error) => {
      const configError =
        error instanceof ConfigLoadError
          ? error
          : new ConfigLoadError({ cause: error });

      setError(configError);
      onError(configError);
    });

    return () => {
      // Prevent load event from firing twice during development
      // Ref: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
      canFireLoadEvent = false;
    };
  }, [url]);

  if (error && throwOnError) {
    // throw error to the nearest error boundry
    throw error;
  }

  if (!config) {
    return fallback ? fallback : null;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

/**
 * Use only in tests. Useful for testing components that rely on configuration.
 */
export const MockConfigProvider = ({
  children,
  config = {},
}: {
  children: ReactNode;
  config?: Partial<Config>;
}) => {
  _config = config as Config;

  return (
    <ConfigContext.Provider value={config as Config}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw Error('useConfig must be used within a ConfigProvider');
  }

  return context;
};

export interface WithConfigProps {
  config: Config;
}

/**
 * Use only with the remaining class-based components which don't support hooks.
 */
export function withConfig<T extends WithConfigProps = WithConfigProps>(
  Component: React.ComponentType<T>,
) {
  const ComponentWithConfig = (props: Omit<T, keyof WithConfigProps>) => {
    const config = useConfig();

    return <Component {...(props as T)} config={config} />;
  };

  const displayName = Component.displayName || Component.name || 'Component';

  ComponentWithConfig.displayName = `withConfig(${displayName})`;

  return ComponentWithConfig;
}

/**
 * Use only when useConfig hook is not feasible (e.g. outside of react).
 */
export const getConfig = () => {
  if (!_config) {
    throw new Error(
      'getConfig() can only be used after ConfigProvider initialisation is complete',
    );
  }

  return _config;
};
