import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { DefaultEvent, getDefaultEventData } from './utils';

declare global {
  interface Window {
    dataLayer?: object[];

    gtag(...args: unknown[]): unknown;
  }
}

if (typeof window !== 'undefined') {
  /**
   * We initialise `window.dataLayer` as early as possible, so that events can be pushed to dataLayer
   * even before the GTM code is initialised. GTM code once it's initialised will pick the predeclared dataLayer
   * and send all event's that were there
   */
  window.dataLayer = window.dataLayer || [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  window.gtag = function (...args: never) {
    // GTM requires `arguments` to be used, not `...args`
    // eslint-disable-next-line prefer-rest-params
    window?.dataLayer?.push(arguments);
  };
}

type GAEVentParameterRecord = Record<
  string,
  string | number | null | undefined
>;

export type GAEventData =
  | GAEVentParameterRecord
  | {
      items: Array<GAEVentParameterRecord>;
    };

export interface GA4ContextType {
  /**
   *
   * Google Analytics 4 working only for the Web browser
   *
   * @param eventName name of the event,
   * Consider using recommended events when possible
   * https://support.google.com/analytics/answer/9267735
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
   *
   * @param data parameters associated with event
   * NOTICE: parameters won't necessarily be populated in reports. Only those, for which dimensions or metrics were defined.
   * Consider using recommended events with their parameters or predefined dimensions https://developers.google.com/analytics/devguides/reporting/data/v1/api-schema
   *
   * Otherwise, a custom dimensions needs to be created in order to is it in reports
   * https://support.google.com/analytics/answer/14240153
   *
   * NOTICE: custom dimension needs to be created prior to sending events.
   * Parameters sent before custom dimension was created won't be captured in reports.
   *
   * NOTICE: Avoid creating highly unique dimensions. This may result a high number of rows in report
   * and as consequence adding the "other" row in the report.
   * https://support.google.com/analytics/answer/13331684
   *
   * Currently added custom dimensions:
   * - app_type: AppType;
   * - environment: EnvironmentType;
   */
  trackEvent: (eventName: string, data?: GAEventData) => void;
}

const GAContext = React.createContext<GA4ContextType | undefined>(undefined);

interface Props {
  isProd: boolean;
  isMobileWeb?: boolean;
  gtmId?: string;
  b2bClientGtmId?: string;
}

const GAProvider = ({
  children,
  isProd = false,
  isMobileWeb,
  gtmId,
  b2bClientGtmId,
}: PropsWithChildren<Props>) => {
  const defaultEventData: DefaultEvent = getDefaultEventData({
    isMobileWeb,
    isProd,
  });

  const trackEvent: GA4ContextType['trackEvent'] = useCallback(
    (eventName, data) => {
      try {
        window.gtag('event', eventName, {
          ...defaultEventData,
          ...data,
        });
      } catch (error) {
        console.error(`Error tracking event: ${eventName}`, error);
      }
    },
    [isProd],
  );

  const triggerDropOffEvent = useCallback(() => {
    trackEvent('dropoff', {
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, [trackEvent]);

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({
        gtmId,
      });
    }
  }, [gtmId]);

  useEffect(() => {
    // For B2B clients direct tracking with their own GTM ID
    if (b2bClientGtmId) {
      TagManager.initialize({
        gtmId: b2bClientGtmId,
      });
    }
  }, [b2bClientGtmId]);

  useEffect(() => {
    window.addEventListener('beforeunload', triggerDropOffEvent);
    return () => {
      window.removeEventListener('beforeunload', triggerDropOffEvent);
    };
  }, [trackEvent, triggerDropOffEvent]);

  return (
    <GAContext.Provider value={{ trackEvent }}>{children}</GAContext.Provider>
  );
};

const useGA4 = () => {
  const context = React.useContext(GAContext);

  if (!context) {
    throw Error('useGA4 must be used within a GAProvider');
  }
  return context;
};

export { GAContext, GAProvider, useGA4 };
