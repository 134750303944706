type AppType = 'mobile_app' | 'mobile_web' | 'web';
type EnvironmentType = 'live' | 'dev';
export interface DefaultEvent {
  app_type: AppType;
  environment: EnvironmentType;
}
export const getDefaultEventData = ({
  isNative = false,
  isProd = false,
  isMobileWeb = false,
}) => {
  let app_type: AppType;
  const environment: EnvironmentType = isProd ? 'live' : 'dev';

  if (isNative) {
    app_type = 'mobile_app';
  } else if (isMobileWeb) {
    app_type = 'mobile_web';
  } else {
    app_type = 'web';
  }

  return {
    app_type,
    environment,
  };
};
