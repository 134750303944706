import './Input.scss';

import classNames from 'classnames';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export type InputProps = ComponentPropsWithoutRef<'input'> & {
  hasError?: boolean;
};

const className = 'c-TextInput';

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { hasError, className: customClassName, ...propsRest } = props;

  return (
    <input
      ref={ref}
      className={classNames(
        className,
        {
          [`${className}--error`]: hasError,
        },
        customClassName,
      )}
      {...propsRest}
    />
  );
});

Input.displayName = 'Input';
