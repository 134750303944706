import * as Yup from 'yup';
import { Resource } from '../interfaces';

type FieldDescription = {
  tests: string[];
  label: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: any;
};

/**
 * Recursively traverses the schema fields tree and extracts field descriptions.
 */
const getFieldDescriptions = (
  fields: Record<string, Yup.SchemaFieldDescription>,
  acc: Record<string, FieldDescription> = {},
  path = '',
) => {
  for (const [key, description] of Object.entries(fields)) {
    acc[`${path}${key}`] = {
      tests:
        'tests' in description
          ? description.tests.map((test) => test.name)
          : [],
      label: 'label' in description ? description.label : undefined,
      meta: 'meta' in description ? description.meta : undefined,
    };

    if ('fields' in description) {
      getFieldDescriptions(description.fields, acc, `${path}${key}.`);
    }
  }

  return acc;
};

const getSchemaDescription = (validationSchema: Yup.ObjectSchema) => {
  return getFieldDescriptions(validationSchema.describe().fields);
};

export const isRequiredField = (
  validationSchema: Yup.ObjectSchema,
  name: string,
): boolean | undefined => {
  return getSchemaDescription(validationSchema)[name]?.tests?.includes(
    'required',
  );
};

export const getFieldLabel = (
  validationSchema: Yup.ObjectSchema,
  name: string,
) => {
  return getSchemaDescription(validationSchema)[name]?.label;
};

export const getFieldOptions = <
  Value,
  Option extends { value: Value; label: string },
>(
  validationSchema: Yup.ObjectSchema,
  name: string,
): Option[] | undefined => {
  return getSchemaDescription(validationSchema)[name]?.meta?.options;
};

export const getFieldLoading = (
  validationSchema: Yup.ObjectSchema,
  name: string,
): boolean | undefined => {
  return getSchemaDescription(validationSchema)[name]?.meta?.loading;
};

export const nullEmptyField = (value: string) => {
  return value?.trim().length ? value.trim() : null;
};

export const lookupModelFromField = (value: string) => {
  return (value?.trim().length ? { id: value } : { id: null }) as Resource;
};
