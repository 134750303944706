import { appEnv } from '../utils/env';

export const API_VERSION_HEADER = 'X-API-VERSION';

export const VERSION_MAX = '99.12.31';

/**
 * To avoid errors overflooding, we send only first mismatch for a single application launch
 */
let versionMismatchDetected = false;

export const checkAPIVersion = (APIVersion: string | null) => {
  if (appEnv.isVersioned() && !versionMismatchDetected) {
    const FEVersion = getCurrentAppVersion();
    if (!!APIVersion && APIVersion !== FEVersion) {
      versionMismatchDetected = true;
      console.warn('API version mismatch', {
        extra: { FEVersion, APIVersion },
      });
    }
  }

  return versionMismatchDetected;
};

export const isInternalVersion = () => {
  const rawVersion = process.env.REACT_APP_VERSION as string;
  return !rawVersion || rawVersion === '0.0.0';
};

export const setOverrideVersion = (version: string | undefined) => {
  try {
    if (version) {
      localStorage.setItem('overrideVersion', version);
    } else {
      localStorage.removeItem('overrideVersion');
    }
  } catch {
    // Do not log this error to Sentry. setOverrideVersion should do nothing in PROD.
    console.error('Failed while trying to set override version');
  }
};

export const getCurrentAppVersion = () => {
  if (typeof window !== 'undefined') {
    const override = localStorage.getItem('overrideVersion');

    if (override) {
      return override;
    }
  }

  const rawVersion = process.env.REACT_APP_VERSION as string;
  // our version format is date-based, but version taken from package.json gets formatted to proper version format
  // e.g. version 23.05.31 gets changed to 23.5.31, without the zero in the month
  // the code below prepares the proper version format for the API and restores the date-based format
  return !isInternalVersion()
    ? rawVersion
        .split('.')
        .map((dateItem) => dateItem.padStart(2, '0'))
        .join('.')
    : rawVersion;
};
