import './Label.scss';

import { forwardRef, ReactNode } from 'react';

export type LabelProps = {
  children: ReactNode;
  htmlFor: string;
  required?: boolean;
};

const className = 'c-Label';

export const Label = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const { required, children, ...propsRest } = props;

  return (
    <label ref={ref} className={className} {...propsRest}>
      {children}
      {required && <span aria-hidden="true">{' *'}</span>}
    </label>
  );
});

Label.displayName = 'Label';
